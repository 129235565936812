/*
Font families defined by this CSS:

- "SpaceGrotesk"                  static "traditional" fonts for older web browsers
- "SpaceGrotesk var"              single-axis variable fonts for all modern browsers
- "SpaceGrotesk var experimental" multi-axis variable fonts for some modern web browsers

Use like this in your CSS:

  :root { font-family: 'SpaceGrotesk', sans-serif; }
  @supports (font-variation-settings: normal) {
    :root { font-family: 'SpaceGrotesk var', sans-serif; }
  }

------------------------- static ------------------------- */
@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("SpaceGrotesk-Light.woff2") format("woff2");
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("SpaceGrotesk-Regular.woff2") format("woff2");
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("SpaceGrotesk-Medium.woff2") format("woff2");
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("SpaceGrotesk-SemiBold.woff2") format("woff2");
}

@font-face {
    font-family: 'SpaceGrotesk';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("SpaceGrotesk-Bold.woff2") format("woff2");
}