/*
Font families defined by this CSS:

- "DMSans"                  static "traditional" fonts for older web browsers
- "DMSans var"              single-axis variable fonts for all modern browsers
- "DMSans var experimental" multi-axis variable fonts for some modern web browsers

Use like this in your CSS:

  :root { font-family: 'DMSans', sans-serif; }
  @supports (font-variation-settings: normal) {
    :root { font-family: 'DMSans var', sans-serif; }
  }

------------------------- static ------------------------- */
@font-face {
    font-family: 'DMSans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("DMSans-Regular.woff2") format("woff2");
}

@font-face {
    font-family: 'DMSans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("DMSans-Italic.woff2") format("woff2");
}

@font-face {
    font-family: 'DMSans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("DMSans-Medium.woff2") format("woff2");
}

@font-face {
    font-family: 'DMSans';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("DMSans-MediumItalic.woff2") format("woff2");
}

@font-face {
    font-family: 'DMSans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("DMSans-BoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: 'DMSans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("DMSans-Bold.woff2") format("woff2");
}